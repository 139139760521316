import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import streetImg from './street.png';
import streetMobile from './streetMobile.jpg';
import './style.scss'; 

function LeafletMap( { className }) {
  const isMobile = window.screen.width < 768;

  return (
      <Container>
        <div className={`leaflet-wrapper mb-5 ${className ? className : ''}`}>
          <Row>
              <Col>
                  <h5>Заповядайте на място с предварителна уговорка - ул. "Ивайло" <b>32</b></h5>
              </Col>  
          </Row>
          <Link target="_blank" to="https://www.google.com/maps/dir//42.4941747,27.4651335/@42.4942456,27.463857,18z/data=!4m2!4m1!3e0?entry=ttu">
            <Row>
              <Col>
              {isMobile
                ? <img src={streetMobile}/>
                : <img src={streetImg}/>
              }
              
                {/* <MapContainer 
                  center={[42.49415, 27.46514]} 
                  zoom={20} 
                  scrollWheelZoom={false}
                  >
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.webp"
                    />
                    <Marker position={[42.49415, 27.46514]}>
                    <Popup>
                        ул. "Ивайло" <b>32</b>
                    </Popup>
                    </Marker>
                </MapContainer> */}
              </Col>
            </Row>
          </Link>
        </div>
      </Container>
  );
}

export default LeafletMap;