import store from "./store/store";
import { setCartProductsAction, setToastifyAction } from './store/actions';

export function addToCart(acticleNumber, title) {
    const currentCartProducts = JSON.parse(window.localStorage.getItem('cartProducts')) || [];
    let articleNumsLocalStorage = currentCartProducts;

    if (!currentCartProducts.includes(acticleNumber)) {
        articleNumsLocalStorage = JSON.stringify([...currentCartProducts, acticleNumber]);
        window.localStorage.setItem('cartProducts', articleNumsLocalStorage);
    }

    let parseNumbersToArrayForStore = articleNumsLocalStorage instanceof Array ? articleNumsLocalStorage : JSON.parse(articleNumsLocalStorage);
    
    store.dispatch(setToastifyAction(`Успешно добавихте ${title} към количката за пазаруване!`));
    store.dispatch(setCartProductsAction(parseNumbersToArrayForStore));
}

export function scrollToTop() {
    window.scrollTo(0, 0);
}

export function isProductPage() {
    const currentUrl = window.location.href;
    const isProductPageArray = currentUrl.split('/');
    const isProductPageValue = isProductPageArray.indexOf('product');
    return isProductPageValue > 0;
}